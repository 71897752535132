<template>
  <ExibeDadosNested

    :data="data"

  />
</template>

<script>
export default {
  props: {
    data: Object,
    namedKeys: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
